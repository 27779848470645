<template>
	<div class="app login-font-mont">
		<!-- Replicated Nav -->
		<replicated-site-nav
			v-if="!!Object.keys(replicatedSiteData).length"
			:replicated="replicatedSiteData" />
		<!-- Default Nav -->
		<AppHeader
			:type="windowWidth === 'xs' ? 'compact': 'extended'"
			:class="!!Object.keys(replicatedSiteData).length ? 'default-nav' : ''"
			:style="Object.keys(replicatedSiteData).length && setNavbarTop ? 'margin-top: 0px;' : ''"
			fixed>
			<b-link
				:class="{'left-position': (windowWidth === 'sm' || windowWidth === 'md')}"
				class="navbar-brand"
				to="/">
				<div
					:id="logoIds().navbar"
					class="navbar-brand-full d-none d-sm-block"
					alt="Logo" />
			</b-link>
			<b-navbar-nav class="ml-auto">
				<lang-dropdown class="mr-3" />
				|
				<button
					v-if="(!Object.keys(replicatedSiteData).length || sponsor.can_register) && !emergencyMode"
					type="button"
					class="btn btn-link d-block text-left">
					<a
						class="h6 font-weight-normal mb-4 pointer bolder-text-primary"
						@click="becomeMember()">
						<b>{{ translate('join_now').toUpperCase() }}</b>
					</a>
				</button>
				<button
					type="button"
					class="btn btn-link d-block text-left">
					<a
						class="h6 font-weight-normal mb-4 mr-3 pointer"
						@click="$router.push({ name: 'Store' })">
						<b>{{ translate('home').toUpperCase() }}</b>
					</a>
				</button>
			</b-navbar-nav>
		</AppHeader>
		<!-- Container -->
		<div
			:id="videoBackground ? 'loginVideoBackground' : 'loginBackground'"
			:type="windowWidth === 'xs' ? 'compact': 'extended'"
			:class=" {
				'noAuthContainer': !!Object.keys(replicatedSiteData).length,
			}"
			class="app flex-row align-items-center">
			<video
				v-if="videoBackground"
				autoplay
				muted
				loop
				playsinline
				preload="auto">
				<source
					:src="videoBackgroundUrl"
					type="video/mp4">
				Your browser does not support the video tag.
			</video>
			<div
				:class="['md'].includes(windowWidth) ? 'mt-5 py-5' : ''"
				:style="['sm', 'xs'].includes(windowWidth) && $route.name == 'Login' ? 'margin-top:5rem' : ''"
				class="container">
				<b-row class="justify-content-center">
					<b-col md="7">
						<!--<div
							v-if="$route.name == 'Login' && isBetweenInterval"
							class="text-center"
							:style="['xs', 'sm'].includes(windowWidth) ? '' : 'margin-top: 3rem'">
							<big-countdown
								size="md"
								:hours="hours.toString()"
								:minutes="minutes.toString()"
								:seconds="seconds.toString()" />
						</div>-->
						<b-card-group class="container">
							<b-card
								class="p-4 border-0 noAuthCard"
								:class="$route.name == 'EmailChanged' ? 'error-card' : ''">
								<div
									:id="logoIds().login"
									:alt="altImage"
									class="navbar-brand-full mx-auto mb-4 d-block pointer"
									@click="$router.push({ name: 'Login' }).catch(() => {})" />
								<router-view :key="$route.fullPath" />
							</b-card>
						</b-card-group>
						<b-card-group
							v-if="$route.name == 'Login'"
							class="container mt-3">
							<b-card class="p-0 border-0 bg-mobile">
								<div class="card-body p-0">
									<div class="row text-center">
										<div class="col-12">
											<h6
												class="bold"
												style="color:#0553c7">
												<strong>{{ translate('download_app_title') }}</strong>
											</h6>
											<p style="color: #424242 !important; font-size: 14px;">
												{{ translate('download_app_message') }}
											</p>
										</div>
										<div
											:class="['sm', 'xs'].includes(windowWidth) ? '' : 'text-right'"
											class="col-md-6 col-sm-12">
											<a href="https://play.google.com/store/apps/details?id=com.velovita.app">
												<img
													:src="require(`@/assets/images/common/mobile-app/${language || 'en'}/play-store.png`)"
													width="70%">
											</a>
										</div>
										<div
											:class="['sm', 'xs'].includes(windowWidth) ? 'mt-2' : 'text-left'"
											class="col-md-6 col-sm-12">
											<a href="https://apps.apple.com/app/velovita-vibe/id1546020462">
												<img
													:src="require(`@/assets/images/common/mobile-app/${language || 'en'}/app-store.png`)"
													width="70%">
											</a>
										</div>
									</div>
								</div>
							</b-card>
						</b-card-group>
					</b-col>
				</b-row>
			</div>
		</div>
		<request-sponsor-modal
			v-model="showStoreModal"
			:redirect-type="redirectType" />
	</div>
</template>
<script>
import { Header as AppHeader } from '@coreui/vue';
import LangDropdown from '@/views/Layouts/Main/LangDropdown';
import { SHOW_FROM_DATE, END_DATE, COUNTDOWN_TIMEZONE } from '@/settings/Countdown';
import WindowSizes from '@/mixins/WindowSizes';
import ReplicatedSite from '@/util/ReplicatedSite';
import ReplicatedSiteNav from '@/components/AdditionalNav';
import { Header, Store, MobileApp } from '@/translations';
import PublicStore from '@/mixins/PublicStore';
import RequestSponsorModal from '@/components/RequestSponsorModal/index.vue';
import { AGENCY_REPLICATED_SITE_NAME } from '@/settings/Replicated';
import { VIDEO_BACKGROUND, VIDEO_BACKGROUND_URL } from '@/settings/Background';
import EmergencyMode from '@/util/EmergencyMode';
// import Countdown from '@/mixins/Countdown';
// import BigCountdown from '@/views/Store/components/BigCountdown';

export default {
	name: 'NoAuthHeader',
	messages: [Header, Store, MobileApp],
	components: {
		// BigCountdown,
		AppHeader,
		LangDropdown,
		ReplicatedSiteNav,
		RequestSponsorModal,
	},
	mixins: [WindowSizes, PublicStore],
	data() {
		return {
			replicated: new ReplicatedSite(),
			setNavbarTop: false,
			countdownStart: SHOW_FROM_DATE,
			countdownEnds: END_DATE,
			countdownTimezone: COUNTDOWN_TIMEZONE,
			videoBackground: VIDEO_BACKGROUND,
			videoBackgroundUrl: VIDEO_BACKGROUND_URL,
			emergencyMode: false,
			emergencyModeChecked: false,
		};
	},
	computed: {
		// Countdown properties
		// countdownBackground() {
		// 	if (this.countdownDone) {
		// 		return {
		// 			background: `url(${this.anniversaryBg})`,
		// 			'background-size': 'cover',
		// 			'border-radius': '0px',
		// 			'background-position': 'top left',
		// 		};
		// 	}
		// 	return {};
		// },
		// anniversaryBg() {
		// 	return require('@/assets/images/themes/Velovita/background_anniversary.png'); // eslint-disable-line global-require
		// },
		altImage() {
			return process.env.VUE_APP_NAME;
		},
		replicatedSiteData() {
			try {
				const { response } = this.replicated.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		loading() {
			return !!this.replicated.data.loading;
		},
		replicatedUsername() {
			return this.$replicated.replicatedId();
		},
	},
	watch: {
		replicatedUsername(newVal) {
			this.replicated.clear();
			if (newVal) {
				this.replicated.getReplicatedSiteNav(newVal).catch(() => {
					this.$replicated.siteOff();
					this.$replicated.siteReplicatedSponsorOff();
					this.$replicated.siteReplicatedPageOff();
				});
			}
		},
	},
	async created() {
		window.addEventListener('scroll', this.handleScroll);
		this.replicated.clear();
		if (this.replicatedUsername === AGENCY_REPLICATED_SITE_NAME) {
			this.$replicated.siteOff();
			this.$replicated.siteReplicatedSponsorOff();
			this.$replicated.siteReplicatedPageOff();
		} else if (this.replicatedUsername) {
			this.replicated.getReplicatedSiteNav(this.replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}

		this.handleSourceQueryParam();
		// start countdown
		// this.useSeconds = true;
		// this.timezone = this.countdownTimezone;
		// this.interval = 1000;
		// this.startTimer();

		// Check emergency mode first
		try {
			const emergencyMode = new EmergencyMode();
			const response = await emergencyMode.checkEmergencyMode();
			this.emergencyMode = response.response.emergency_mode;
		} catch (error) {
			console.error('Failed to check emergency mode:', error);
		} finally {
			this.emergencyModeChecked = true;
		}
	},
	methods: {
		handleScroll() {
			this.setNavbarTop = window.scrollY > 10;
		},
		startTimer() {
			if (!this.countdownEnds) {
				return;
			}
			this.countdown(this.countdownEnds, this.countdownStart);
		},
	},
};
</script>
<style>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	.login-font-mont {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.default-nav.app-header.navbar[type="extended"]{
		margin-top: 70px;
	}
	.default-nav.app-header.navbar[type="compact"]{
		margin-top: 70px;
	}
	.noAuthContainer.app[type="extended"]{
		margin-top: 100px;
	}
	.noAuthContainer.app[type="compact"]{
		margin-top: 100px;
	}
	.bg-mobile {
		background: rgba(255, 255, 255, 0.6) !important;
	}
</style>
